export const stage = process.env.VUE_APP_STAGE || 'ekadar';

if (!stage) {
  throw new Error('stage is undefined');
}

// This is optained at prebuild step, in package.json
export const identityPoolId = process.env.VUE_APP_POOL_ID || 'eu-central-1:55a67066-5277-4492-8189-01abb018d2d2';
if (!identityPoolId) {
  throw new Error('identityPoolId is undefined');
}
